// axiosInstance.js
import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: "https://app.datawiz.ir",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

api.interceptors.request.use(
  (config) => {
    const csrfToken = Cookies.get("XSRF-TOKEN");
    if (csrfToken) {
      config.headers["X-XSRF-TOKEN"] = csrfToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const getCsrfToken = async () => {
  try {
    await api.get("/sanctum/csrf-cookie");
  } catch (error) {
    console.error("Error fetching CSRF token:", error);
  }
};

export default api;
