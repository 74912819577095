<template>
    <div class="container">
        <HeaderSection />
        <div class="main">
            <div class="main-actions">
                <div class="workspace">
                    <span class="workspace-logo"></span>
                    <span class="workspace-name">دیجیتال مارکتینگ هادی</span>
                </div>
                <div class="actions">
                    <span class="settings">S</span>
                    <button class="primary-outline-btn">
                        + {{ $t("New Project") }}
                    </button>
                </div>
            </div>
            <div class="notification">
                <span class="notif-text">{{ $t("trial notif") }}</span>
                <button class="primary-btn">
                    {{ $t("Unlock Full Trial") }}
                </button>
            </div>
            <div class="projects">
                <div v-if="projects.length === 0" class="empty-projects">
                    <span class="title">{{ $t("Create a New Project") }}</span>
                    <span class="description">{{
                        $t("empty project description")
                    }}</span>
                </div>
                <div v-else class="project-list">
                    <div
                        v-for="project in projects"
                        :key="project.id"
                        class="project-item"
                        @click="router.push(`/project/${project.id}`)"
                    >
                        <h3>{{ project.name }}</h3>
                        <p>{{ project.description }}</p>
                        <small>{{
                            new Date(project.created_at).toLocaleDateString()
                        }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import HeaderSection from "../Layout/HeaderSection.vue";
import api from "../../axiosInstance";

export default {
    components: {
        HeaderSection,
    },
    setup() {
        const router = useRouter();
        const projects = ref([]);
        const token = localStorage.getItem("access_token");

        const fetchUserProfile = async () => {
            if (token) {
                api.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${token}`;
                try {
                    const response = await api.get("/api/user-profile");
                    console.log("User profile:", response.data);
                } catch (error) {
                    console.error("Error fetching user profile:", error);
                }
            } else {
                router.push("/login");
            }
        };

        const fetchProjects = async () => {
            try {
                const response = await api.get("/api/projects");
                projects.value = response.data;
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        onMounted(() => {
            fetchUserProfile();
            fetchProjects();
        });

        return {
            projects,
            router
        };
    },
};
</script>
