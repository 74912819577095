<template>
  <form :action="formConfig.action" :method="formConfig.method" v-bind="formConfig.attributes" @submit.prevent="handleSubmit">
    <div v-for="(field, index) in formConfig.fields" :key="index">
      <label v-if="field.label" :for="field.name">{{ $t(field.label) }}</label>
      <input v-if="field.type !== 'textarea' && field.type !== 'select' && field.type !== 'submit'"
             :type="field.type"
             :name="field.name"
             v-bind="translateAttributes(field.attributes)"
             v-model="formData[field.name]" />
      <input v-if="field.type === 'submit'"
             :type="field.type"
             :value="$t(field.value)">
      <textarea v-if="field.type === 'textarea'"
                :name="field.name"
                v-bind="translateAttributes(field.attributes)"
                v-model="formData[field.name]"></textarea>
      <select v-if="field.type === 'select'"
              :name="field.name"
              v-bind="translateAttributes(field.attributes)"
              v-model="formData[field.name]">
        <option v-for="(option, index) in field.options" :key="index" :value="option.value">
          {{ $t(option.label) }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
export default {
  name: 'CustomForm',
  props: {
    formConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {}
    };
  },
  methods: {
    handleSubmit() {
      this.$emit('form-submit', this.formData);
    },
    translateAttributes(attributes) {
      const translatedAttributes = { ...attributes };
      if (translatedAttributes.placeholder) {
        translatedAttributes.placeholder = this.$t(translatedAttributes.placeholder);
      }
      return translatedAttributes;
    }
  }
};
</script>

<style scoped>
form {
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 8px;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

input, textarea, select {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-family);
}

input:focus, textarea:focus, select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 5px var(--primary-color);
}
</style>
