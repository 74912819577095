<template>
  <div>
    <h1>{{ $t('login') }}</h1>
    <CustomForm :formConfig="formConfig" @form-submit="login" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import api, { getCsrfToken } from "@/axiosInstance"; // Adjust the path accordingly
import CustomForm from '../components/CustomForm.vue'; 

export default {
  components: {
    CustomForm
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const router = useRouter();

    const checkToken = async () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await api.get("/api/check-token", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            router.push("/dashboard");
          }
        } catch (error) {
          console.error("Invalid token:", error);
        }
      }
    };

    const login = async (formData) => {
      await getCsrfToken();
      try {
        const response = await api.post("/api/login", {
          email: formData.email,
          password: formData.password,
        });

        if (response.status === 200) {
          localStorage.setItem("access_token", response.data.access_token);
          router.push("/dashboard");
        } else {
          console.error("Login failed");
        }
      } catch (error) {
        console.error("Error logging in:", error);
      }
    };

    onMounted(() => {
      checkToken();
    });

    const formConfig = {
      method: 'POST',
      attributes: {
        class: 'custom-form login',
        id: 'loginForm'
      },
      fields: [
        {
          type: 'email',
          name: 'email',
          label: 'email',
          attributes: {
            required: true,
            placeholder: 'Enter your email',
            'v-model': email.value
          }
        },
        {
          type: 'password',
          name: 'password',
          label: 'password',
          attributes: {
            required: true,
            placeholder: 'Enter your password',
            'v-model': password.value
          }
        },
        {
          type: 'submit',
          value: 'login'
        }
      ]
    };

    return {
      formConfig,
      login
    };
  }
};
</script>


<style scoped>
.login {
  max-width: 400px;
  margin: 0 auto;
}
</style>
