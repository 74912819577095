import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginPage.vue';
import Dashboard from '../views/Dashboard/DashboardPage.vue';
import ProjectPage from '../views/Dashboard/Project/ProjectPage.vue';
import WorkflowPage from '../views/Dashboard/Workflow/WorkflowPage.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/project/:id',
    name: 'ProjectPage',
    component: ProjectPage
  },
  {
    path: '/project/:projectId/workflow/:id',
    name: 'WorkflowPage',
    component: WorkflowPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
