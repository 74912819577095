<template>
    <HeaderSection />
    <div class="main">
        <div class="main-actions">
            <div class="workspace">
                <span class="workspace-logo"></span>
                <span class="workspace-name"> {{ workflow.name }}</span>
            </div>
            <div class="actions">
                <span class="settings">
                    <i class="bi bi-gear"></i>
                </span>
                <span class="settings" @click="startWorkflow">
                    <i class="bi bi-play"></i>
                </span>
                <button class="primary-outline-btn" @click="showPopup = true">
                    + {{ $t("New Module") }}
                </button>
            </div>
        </div>
        <div class="notification">
            <span class="notif-text">{{ $t("trial notif") }}</span>
            <button class="primary-btn">
                {{ $t("Unlock Full Trial") }}
            </button>
        </div>
        <div class="projects">
            <div
                v-if="
                    Modules.length === 0 
                "
                class="empty-projects"
            >
                <span class="title">{{ $t("Create a New Module") }}</span>
                <span class="description">{{
                    $t("empty Workspace description")
                }}</span>
            </div>
            <div v-else class="project-list">
                <div
                    v-for="Module in Modules"
                    :key="Module.id"
                    class="project-item"
                    @click="router.push(`/workflow/${workflow.id}`)"
                >
                    <h3>{{ Module.name }}</h3>
                    <p>{{ Module.description }}</p>
                    <small>{{
                        new Date(Module.created_at).toLocaleDateString()
                    }}</small>
                    <div class="flex">
                        <button
                            class="primary-btn"
                            @click.stop="deleteModule(Module.id, Module.type)"
                        >
                            {{ $t("Delete Module") }}
                        </button>
                        <button class="primary-btn">
                            {{ $t("Edit Module") }}
                        </button>
                    </div>
                    <div
                        v-if="response && response[Module.name]"
                        class="show-response"
                    >
                        {{ response[Module.name] }}
                    </div>
                </div>
            </div>
        </div>
        <!-- Response popup -->
        <div class="response" v-if="showResponse">
            <span class="primary-btn" @click="showResponse = false">{{
                $t("Close")
            }}</span>
            <pre>
                {{ response }}
            </pre>
        </div>
        <!-- New Module Popup -->
        <div v-if="showPopup" class="popup">
            <div class="popup-content">
                <h2>{{ $t("Create New Module") }}</h2>
                <form @submit.prevent="createModule">
                    <div>
                        <label for="name">{{ $t("Module Name") }}</label>
                        <input
                            type="text"
                            id="name"
                            v-model="moduleForm.name"
                            required
                        />
                    </div>
                    <div>
                        <label for="type">{{ $t("Module Type") }}</label>
                        <select id="type" v-model="moduleForm.type" required>
                            <option value="api">
                                {{ $t("API Endpoint") }}
                            </option>
                            <option value="variable">
                                {{ $t("Variable") }}
                            </option>
                        </select>
                    </div>

                    <!-- API Endpoint Fields -->
                    <div v-if="moduleForm.type === 'api'">
                        <div>
                            <label for="method">{{ $t("Method") }}</label>
                            <input
                                type="text"
                                id="method"
                                v-model="moduleForm.method"
                                required
                            />
                        </div>
                        <div>
                            <label for="url">{{ $t("URL") }}</label>
                            <input
                                type="text"
                                id="url"
                                v-model="moduleForm.url"
                                required
                            />
                        </div>
                        <div>
                            <label for="headers">{{ $t("Headers") }}</label>
                            <textarea
                                id="headers"
                                v-model="moduleForm.headers"
                            ></textarea>
                        </div>
                        <div>
                            <label for="body">{{ $t("Body") }}</label>
                            <textarea
                                id="body"
                                v-model="moduleForm.body"
                            ></textarea>
                        </div>
                    </div>

                    <!-- Variable Fields -->
                    <div v-if="moduleForm.type === 'variable'">
                        <div>
                            <label for="variableName">{{
                                $t("Variable Name")
                            }}</label>
                            <input
                                type="text"
                                id="variableName"
                                v-model="moduleForm.variableName"
                                required
                            />
                        </div>
                        <div>
                            <label for="value">{{ $t("Value") }}</label>
                            <input
                                type="text"
                                id="value"
                                v-model="moduleForm.value"
                            />
                        </div>
                    </div>

                    <div class="flex gap-10">
                        <button type="submit" class="primary-btn">
                            {{ $t("Create") }}
                        </button>
                        <button
                            class="primary-outline-btn"
                            type="button"
                            @click="showPopup = false"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import api from "@/axiosInstance";
import HeaderSection from "../../Layout/HeaderSection.vue";

export default {
    components: {
        HeaderSection,
    },
    setup() {
        const router = useRoute();
        const workflowId = ref(router.params.id);
        const projectId = ref(router.params.projectId);
        const workflow = ref(null);
        const Modules = ref(null);
        const token = localStorage.getItem("access_token");
        const showPopup = ref(false);
        const showResponse = ref(false);
        const moduleForm = ref({
            name: "",
            type: "api", // default type
            method: "",
            url: "",
            headers: "",
            body: "",
            variableName: "",
            value: "",
        });
        const response = ref(null);

        if (token) {
            api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        const fetchWorkflow = async () => {
            try {
                const response = await api.get(
                    `/api/projects/${projectId.value}/workflows/${workflowId.value}`
                );
                workflow.value = response.data;
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        const fetchModules = async () => {
            try {
                const [APIs, Variables] = await Promise.all([
                    api.get(`/api/workflows/${workflowId.value}/api-endpoints`),
                    api.get(`/api/workflows/${workflowId.value}/variables`),
                ]);

                // Assigning types to differentiate between modules
                const apiModules = APIs.data.map((api) => ({
                    ...api,
                    type: "api",
                }));
                const variableModules = Variables.data.map((variable) => ({
                    ...variable,
                    type: "variable",
                }));

                // Combine both types of modules
                Modules.value = [...apiModules, ...variableModules];
            } catch (error) {
                console.error("Error fetching modules:", error);
            }
        };

        const startWorkflow = async () => {
            try {
                const result = await api.post(
                    `/api/workflows/${workflowId.value}/start`
                );
                console.log("Workflow started successfully");
                response.value = result.data.execution.data; // Assign response data to `response`
            } catch (error) {
                console.error("Error starting workflow:", error);
            }
        };
        const deleteModule = async (moduleId, moduleType) => {
            try {
                let endpoint;
                if (moduleType === "api") {
                    endpoint = `/api/workflows/${workflowId.value}/api-endpoints/${moduleId}/`;
                } else if (moduleType === "variable") {
                    endpoint = `/api/workflows/${workflowId.value}/variables/${moduleId}/`;
                }

                if (endpoint) {
                    await api.delete(endpoint);
                    Modules.value = Modules.value.filter(
                        (module) => module.id !== moduleId
                    );
                    console.log("Module deleted successfully");
                }
            } catch (error) {
                console.error("Error deleting module:", error);
            }
        };

        const createModule = async () => {
            try {
                const endpoint =
                    moduleForm.value.type === "api"
                        ? `/api/workflows/${workflowId.value}/api-endpoints`
                        : `/api/workflows/${workflowId.value}/variables`;

                const data =
                    moduleForm.value.type === "api"
                        ? {
                              name: moduleForm.value.name,
                              method: moduleForm.value.method,
                              url: moduleForm.value.url,
                              headers: moduleForm.value.headers,
                              body: moduleForm.value.body,
                          }
                        : {
                              name: moduleForm.value.variableName,
                              value: moduleForm.value.value,
                          };

                await api.post(endpoint, data);
                showPopup.value = false;
            } catch (error) {
                console.error("Error creating module:", error);
            }
        };

        onMounted(() => {
            fetchWorkflow();
            fetchModules();
        });

        return {
            projectId,
            workflow,
            Modules,
            router,
            startWorkflow,
            deleteModule,
            showPopup,
            showResponse,
            response,
            moduleForm,
            createModule,
        };
    },
};
</script>
