import { createApp, watch } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import fa from './locales/fa.json';
import en from './locales/en.json';
import './assets/css/style.css';
import './assets/fonts/bootstrap-icons/font/bootstrap-icons.min.css';

const messages = {
  fa: fa,
  en: en
};

const i18n = createI18n({
  locale: 'fa', // Set the default locale to Persian
  fallbackLocale: 'en', // Set the fallback locale to English
  messages
});

const app = createApp(App);

app.use(router);
app.use(i18n);

const updateHtmlLangAndDir = (locale) => {
  const html = document.documentElement;
  html.setAttribute('lang', locale);
  if (locale === 'fa') {
    html.setAttribute('dir', 'rtl');
  } else {
    html.setAttribute('dir', 'ltr');
  }
};

// Set initial language and direction
updateHtmlLangAndDir(i18n.global.locale);

// Watch for locale changes and update the html attributes
watch(
  () => i18n.global.locale,
  (newLocale) => {
    updateHtmlLangAndDir(newLocale);
  }
);

app.mount('#app');
