<template>
    <nav class="navbar">
        <div>
            <a href="/dashboard">
                <img src="../../assets/img/logo-white.webp" class="logo" />
            </a>
        </div>
        <div>
            <a href="/help" class="menu-item">{{ $t("Help") }}</a>
            <div class="wallet">
                <span class="plan">{{ $t("TRIAL") }}</span>
                <span class="balance">
                    50,000
                    <span class="currency">تومان</span>
                </span>
            </div>
            <div class="profile">
                <span class="avatar" @click="toggleDropdown"></span>
                <div v-if="dropdownOpen" class="dropdown-menu">
                    <a href="/profile">{{ $t("Profile") }}</a>
                    <a href="/billings">{{ $t("Billings") }}</a>
                    <a @click.prevent="logout">{{ $t("Logout") }}</a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { useRouter } from "vue-router";
import api, { getCsrfToken } from "../../axiosInstance"; 

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    async logout() {
      const token = localStorage.getItem("access_token");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      await getCsrfToken();

      try {
        await api.post("/api/logout", {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        localStorage.removeItem("access_token");
        document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT";

        this.router.push("/login");
      } catch (error) {
        console.error("Error logging out:", error);
      }
    },
  },
};
</script>

