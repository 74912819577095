<template>
    <HeaderSection />
     <div class="main">
            <div class="main-actions">
                <div class="workspace">
                    <span class="workspace-logo"></span>
                    <span class="workspace-name">{{project.name}}</span>
                </div>
                <div class="actions">
                    <span class="settings">
                        <i class="bi bi-gear"></i>
                    </span>
                    <button class="primary-outline-btn">
                        + {{ $t("New Workspace") }}
                    </button>
                </div>
            </div>
            <div class="notification">
                <span class="notif-text">{{ $t("trial notif") }}</span>
                <button class="primary-btn">
                    {{ $t("Unlock Full Trial") }}
                </button>
            </div>
            <div class="projects">
                <div v-if="workflows.length === 0" class="empty-projects">
                    <span class="title">{{ $t("Create a New Workspace") }}</span>
                    <span class="description">{{
                        $t("empty Workspace description")
                    }}</span>
                </div>
                <div v-else class="project-list">
                    <div
                        v-for="workflow in workflows"
                        :key="workflow.id"
                        class="project-item"
                        @click="router.push(`/project/${projectId}/workflow/${workflow.id}`)"
                    >
                        <h3>{{ workflow.name }}</h3>
                        <p>{{ workflow.description }}</p>
                        <small>{{
                            new Date(workflow.created_at).toLocaleDateString()
                        }}</small>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/axiosInstance';
import HeaderSection from "../../Layout/HeaderSection.vue";


export default {
    components: {
        HeaderSection,
    },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const projectId = ref(route.params.id);
    const project = ref(null);
    const workflows = ref(null);
    const token = localStorage.getItem("access_token");


    if (token) {
        api.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${token}`;
    }
    const fetchProject = async () => {
      try {
        const response = await api.get(`/api/projects/${projectId.value}`);
        project.value = response.data;
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    const fetchworkflows = async () => {
      try {
        const response = await api.get(`/api/projects/${projectId.value}/workflows`);
        workflows.value = response.data;
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    onMounted(() => {
      fetchProject();
      fetchworkflows();
    });

    return {
      project,
      projectId,
      workflows,
      router
    };
  }
};
</script>

<style>
/* Add your styles here */
</style>
